import { createRouter, createWebHistory } from "vue-router";
import { validateToken } from "@/utils/validation";

const routes = [
  {
    path: "/",
    component: () => import("../layout/layout_page.vue"),
    children: [
      {
        path: "/",
        name: "index-page",
        component: () => import("../components/Index.vue"),
      },
      {
        path: "/pricing",
        name: "pricing-page",
        component: () => import("../components/Pricing.vue"),
      },
      {
        path: "/Privacy-Policy",
        name: "Privacy-Policy",
        component: () => import("../components/Privacy-Policy_page.vue"),
      },
      {
        path: "/smallbusinesses",
        name: "small-businesses",
        component: () => import("../components/small-businesses.vue"),
      },
      {
        path: "/soletraders",
        name: "sole-traders",
        component: () => import("../components/sole-traders.vue"),
      },
      {
        path: "/Terms-of-use",
        name: "Terms-of-use",
        component: () => import("../components/Terms-of-use_Page.vue"),
      },
      {
        path: "/FAQ",
        name: "Faq_Page",
        component: () => import("../components/Faq_Page.vue"),
      },
      {
        path: "/Features",
        name: "services-page",
        component: () => import("../components/Services.vue"),
      },
      {
        path: "/Blog",
        name: "Blog-page",
        component: () => import("../components/Blog_Page.vue"),
      },
      {
        path: "/Blog",
        name: "Blog-page",
        component: () => import("../components/Blog_Page.vue"),
      },
      {
        path: "/BlogDetail",
        name: "BlogDetail-Page",
        component: () => import("../components/BlogDetail_Page.vue"),
      },
      {
        path: "/BlogDetail_2",
        name: "BlogDetail_Page_2",
        component: () => import("../components/BlogDetail_Page_2.vue"),
      },
      {
        path: "/BlogDetail_3",
        name: "BlogDetail_Page_3",
        component: () => import("../components/BlogDetail_Page_3.vue"),
      },
      {
        path: "/cookies",
        name: "cookies_Page",
        component: () => import("../components/cookies_Page.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("../layout/layout2_page.vue"),
    beforeEnter: (to, from, next) => {
      if (validateToken(localStorage.getItem("token")) == false)
        next({ name: "login-page" });
      else next();
    },
    children: [
      {
        path: "/WebHome",
        name: "WebHome",
        component: () => import("../components/Web_Dashboard/WebHome.vue"),
      },

      {
        path: "/Subscriptions_Page",
        name: "Subscriptions_Page",
        component: () =>
          import("../components/Web_Dashboard/Subscriptions_Page.vue"),
      },
      {
        path: "/SubscriptionsCompany_Page/:id",
        name: "SubscriptionsCompany_Page",
        component: () =>
          import("../components/Web_Dashboard/SubscriptionsCompany_Page.vue"),
      },
      {
        path: "/Request_transfer_Page",
        name: "Request_transfer_Page",
        component: () =>
          import("../components/Web_Dashboard/Request_transfer_Page.vue"),
      },
      {
        path: "/Account_Settings_Page",
        name: "Account_Settings_Page",
        component: () =>
          import("../components/Web_Dashboard/Account_Settings_Page.vue"),
      },
      {
        path: "/Profile",
        name: "Profile-page",
        component: () => import("../components/Profile-page.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login-page",
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/pricingplan",
    name: "pricing-plan-page",
    component: () => import("../components/Pricing_Plan.vue"),
    meta: { requiresActiveUser: true },
  },
  {
    path: "/callback/success",
    name: "payment-success",
    component: () => import("../components/PaymentSuccess_Page.vue"),
    meta: { requiresActiveUser: true },
  },
  {
    path: "/callback/error",
    name: "payment-failed",
    component: () => import("../components/PaymentFailed_Page.vue"),
    meta: { requiresActiveUser: true },
  },
  {
    path: "/signup",
    name: "sign-up",
    component: () => import("../components/sign-up.vue"),
  },

  {
    path: "/forgetpage",
    name: "forget-page",
    component: () => import("../components/forget-page.vue"),
  },
  {
    path: "/OtpVerification",
    name: "OtpVerification-page",
    component: () => import("../components/OtpVerification_Page.vue"),
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("../components/Resetpassword_Page.vue"),
  },
];
const router = createRouter({ history: createWebHistory(), routes });


router.beforeEach((to, from, next) => {
  if (to.path === "/signup") {
    window.location.href = process.env.VUE_APP_FLEDGER_APP_DOMAIN+"/signup";
  } else {
    next();
  }
});
  
// router.beforeEach((to, from, next) => {
//   if (to.meta.requiresActiveUser) {
//     const activeUser = JSON.parse(localStorage.getItem("activeUser"));
//     if (activeUser) {
//       next();
//     } else {
//       next("/signup");
//     }
//   } else {
//     next();
//   }
// });

// router.beforeEach((to, from, next) => {
//   const activeUser = JSON.parse(localStorage.getItem("activeUser"));

//   if (activeUser && to.path === "/") {
//     next("/WebHome"); // Redirect logged-in users from root URL to /WebHome
//   } else if (to.meta.requiresActiveUser) {
//     if (activeUser) {
//       next();
//     } else {
//       next("/signup");
//     }
//   } else {
//     next();
//   }
// });

export default router;
